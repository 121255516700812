.docs h1 {
    margin-top: 28px;
    margin-bottom: 14px;
}

.docs pre {
    font-family: monospace;
    background-color: #dee4e9;
    color: #292B2C;
    padding: 1em;
    font-size: 14px;
    border-radius: 3px;
    overflow: auto;
}

.docs p {
    line-height: 1.5;
}

.inline-code {
    background-color: #0C2238;
    color: #dddddd;
    font-style: normal;
    font-size: 13px;
    padding: 0 .5em;
}

.video-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.terms {
	background:white !important;
	font-size:x-small !important;
	width:80%;
	white-space:pre-wrap;
	word-wrap: break-word;
}
