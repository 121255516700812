/* Extensions to Theme. Similar to defunct nova-light */

body a {
  color: #007ad9;
  text-decoration: none;
}
body a:hover {
  color: #116fbf;
}
body a:active {
  color: #005b9f;
}

.p-dropdown-item  {
	font-size:small;
}


/* Place your extensions here to make migration to a new release easier. */

.layout-topbar-title {
	font-size: x-large;
	max-height:1.4em;
	text-overflow: ellipsis;
	overflow:hidden;
}

.layout-topbar-icons {
}

.layout-topbar-myrooms-icon {
	font-size:2em;
}

.user-dropdown {
	background:#eee !important;
	padding:0.2rem;
}

.cwtopbar {
}

.lmbtn {
	margin-top:2px;
}

.layout-test-server .layout-topbar {
	background:green;
	background-image:linear-gradient(to right, #283c86, #45a247);
	/* https://uigradients.com/#Meridian */
	/* http://mrmrs.cc/gradients/ good site for gradients */
	/* https://clrs.cc/ for colors */
}

.layout-logo {
	margin-top: 29px !important;
	margin-bottom: 29px !important;
}

.layout-logo-icon {
	width: 120px;
}

.layout-logo-text {
	font-size: x-large;
	padding:0 0.5em;
	color:#ccc;
}

.layout-test-server .layout-logo-text {
	color:teal;
}

.layout-logo-caveat {
	font-size:xx-small;
	text-align:center;
	color:#999;
}

.circ-prog-wrap {
	margin:0 0.5em;
	position:relative;
}

.circ-prog {
	max-height:2em;
	max-width:4em;
}

/*
.circ-prog {
	margin-top:-1em;
	margin-left:0.8em;
}
*/

.circ-prog-wrap .CircularProgressbar-text {
	fill:yellow;
	font-size:1.7rem;
}
.circ-prog-wrap .CircularProgressbar-path {
	stroke: yellow;
}

.circ-prog-panel {
	display:none;
	position:absolute;
	top:1em;
	right:0;
	background:white;
	border:2px solid #ccc;
	color:black;
	padding:0 1em;
	font-size:small;
}

.circ-prog-wrap:hover .circ-prog-panel {
	display:block;
}

/* User menu dropdown >> */

.p-menubar {
	border:2px solid red;
}

.narrow {
	margin:0 !important;
	padding:0 !important;
	width:0.5em !important;
}

.usermenuicon {
	color:white !important;
}

.layout-topbar-search-icon {
	cursor:pointer;
}

/* User menu dropdown << */

.hide {
	display:none;
}

.hide-tab {
	display:none !important;
}

.invisible {
	visibility:hidden;
}

dt {
	margin-top:0.5em;
	font-weight:bold;
	font-size:small;
}

.modal-command {
	width:80vw !important;
	max-height:90vh !important;
	overflow-y: scroll !important;
}

.mini-modal-command {
	width:50vw !important;
}

.modal-35-command {
	width:35vw !important;
}
.modal-35-command .p-dialog-content {
	min-height:40vh;
}

.medium-modal-command {
	width:50vw !important;
}
.medium-modal-command .p-dialog-content {
	min-height:50vh;
}

.marge-modal-command {
	width:60vw !important;
}
.marge-modal-command .p-dialog-content {
	min-height:60vh;
}

.choose-clients {
	height:90vh;
	width:90vw;
}

.choose-clients .choose-mini {
	margin:4px 0;
}

.add-client {
	width:80vw;
}

.add-client-manual {
	width:40%;
}

.add-client-contacts {
	border:1px solid #ccc;
	margin:1em 0 0 0;
	padding:1em;
}

.create-edit-contact {
	border:2px solid #999;
	border-radius:8px;
	margin:0.5em 0;
	padding:1em 0.5em;
	background:#eee;
}

.create-edit-sel {
	border:2px solid #ccc;
	border-bottom:0;
}

.edit-contact-email {
	font-weight:bold;
	font-size:small;
}

.merge-src {
	font-size:small;
	padding:0.5em;
	background:#ddf;
}

.merge-dest:hover .mkroom-contact{
	border-color:green !important;
	color:green;
}

.email-kinds-dropdown {
}

.edit-emails-legend {
	font-size:x-small;
}

.preview-triggers {
	border-top:1px solid #ddd;
	border-bottom:1px solid #ddd;
}

/*
.new-email .p-dialog-content {
	min-height:80vh;
}

.new-email-edit-note {
}

.new-email-edit-note .p-editor-content {
	height:calc(80vh - 300px);
}
*/

.new-email {
	height:calc(100vh - 150px);
} 

.new-email .p-tabview-panels {
	min-height:calc(100% - 40px);
}

.new-email .p-tabview-panel .p-tabview-panel {
	min-height:calc(100vh - 360px) !important;
}

.edit-body {
}

.edit-body .p-editor-content {
	height:calc(100vh - 420px);
}

.draft-save-message {
	margin-left: 50px;
	color: #999;
}

.profile-alt-signature {
	margin-top: 15px;
}

.profile-signature-buttons {
	margin-top: 15px;
}

.profile-signatures .p-fieldset .p-fieldset-legend {
	padding: 1rem;
	border: 1px solid #dee2e6;
	color: #495057;
	background: #f8f9fa;
	font-weight: 600;
	border-radius: 3px;
}

/* with suneditor */
/* suneditor heights are set in code. css is not working 
	may be because sun-editor.css is loaded afterwards */

/* Compose Window Editor height based on to-mode: s, m, L */
.edit-body.to-mode-s .p-editor-content {
	height:calc(100vh - 370px);
}

.edit-body.to-mode-m .p-editor-content {
	height:calc(100vh - 420px);
}

.edit-body.to-mode-L .p-editor-content {
	height:calc(100vh - 536px);
}

.mobile-compose .edit-body .p-editor-content {
	min-height:6em;
	height:calc(100vh - 20em);
}

.edit-person.p-dialog {
	width:50vw;
	height:70vh;
}
.edit-person .label {
	color:#999 !important;
}


/*----- Userlist / staff -----*/

ul.user-list {
	list-style:none;
	padding:0;
	margin:0;
	border:1px solid #ccc;
}

ul.user-list li {
	text-align:right;
	padding:0.5em;
	border-bottom:1px solid #ccc;
}

ul.user-list li:last-child {
	border:0;
}

ul.user-list li .name {
	font-size:large;
}

ul.user-list li .email {
	color:black;
}

ul.user-list .fa {
	font-size:x-large;
}

.user-admin {
	font-weight:bold;
}

.create-room .p-inputtext {
}

.create-room .sel-clients {
	margin: 0 1em;
}

.select-staff .p-button {
	margin: 0 1em 0 0;
}
.select-staff .p-button-primary {
	background:#ddf;
	color:black;
}

.create-room-staffs .p-fieldset {
}

.create-count {
	color:#aaa;
	margin:0 1em;
	font-size:1rem;
}

.contact {
	cursor:pointer;
}

.pointer {
	cursor:pointer;
}

.mkroom-contact .p-button {
	margin-right:0.5em;
	font-size:x-small;
}

.mkroom-contact-edit input{
	width:calc(100%-1em);
}

.mkroom-contact {
	margin:0 1em 1em 0;
	border:1px solid #ddd;
	border-radius:8px;
	padding:0.5em;
	background:#fafafa;
}

.mkroom-contact .name {
	font-weight:bold;
}
.mkroom-contact .email {
	font-size:small;
	color:#999;
}
.mkroom-contact .tel {
	color:#666;
}
.mkroom-contact a {
	margin:0 1em 0 0;
	font-size:small;
}

.contact:hover {
	background:#ddf;
}

.contact .name {
	font-weight:bold;
}

.contact .email {
	font-size:emall;
	color:#999;
}

.contact.sel {
	background:#eee;
	border:1px solid #333;
}


.contact-groups .p-card:hover {
	background:#ddf;
}

.contact-groups .p-card {
	margin:1em;
}

.contact-groups .contact {
	margin: 0.25em 0;
}

.selected-clients .contact {
	margin: 0 0.25em;
}

.cemail-comment {
	font-style:italic;
	font-size:small;
	margin-bottom:0.5em;
	color:#666;
}

/*----- emails and Tasks -----*/

.summary {
/*
	border-bottom:1px solid #ccc;
	padding:0.5em;
	margin-bottom:0.5em;
	*/
}

.summary:hover {
	/*
	background:#f4f4ff;
	*/
	cursor:pointer;
}

.subthread {
	margin-left:2em !important;
}

.thread-header {
	margin-bottom:1em !important;
	padding:0 0.8em;
}

.from {
	font-weight:bold;
}

.date {
	text-align:right;
	font-size:small;
}

.blogdate {
}

.blogdate_date {
	color:#333;
}

.blogdate_time {
	font-size:x-small;
	color:#666;
}

.taskname_author {
	color:#999;
	font-weight:bold;
}

.subject {
}

.to-cc {
	font-size:small;
}

.item-detail .summary {
	background: #eef;
}

.email-body {
	padding:0 1em;
}

.email-body table {
	border-collapse:collapse;
}

.email-body td,th {
	padding:0.25em 0.5em;
}

.email-body td p {
	margin-block-end:0px;
	margin-block-start:0px;
}

.email-body pre {
	/* email sent from phones use <pre>. Wrap them anyway. Scroll bars not visible */
	white-space: pre-wrap;
	white-space: -moz-pre-wrap;
	white-space: -pre-wrap;
	white-space: -o-pre-wrap;
	word-wrap: break-word;
}

.priority-buttons .p-highlight {
	background: #ddf !important;
	color:#333 !important;
}

.priority {
	color:#999;
}

.priority .Urgent {
	font-weight:bold;
	color:red;
}

.priority .High {
	color:red;
}

.priority .Normal {
	color:#333;
}

.priority .Low {
	color:#999;
}

.colname {
	color:#888;
}

.hilite {
	font-weight:bold;
}

.task-to-choose {
	background:yellow;
	font-size:x-small;
	font-weight:bold;
}

.task-body .p-panel-titlebar {
	/*
	background:#dfd !important;
	*/
}

.task-body .p-panel {
	/*
	border:1px solid #aaa !important;
	box-shadow: 5px 5px 5px #ccc;
	*/
}

.task-note {
	border-bottom:1px solid #ccc;
	margin:0.5em 0;
}

.task-note-border {
	border:1px dotted #ccc;
}

.task-note-header {
	background:#f2f2f2;
	padding:0.3em;
	font-size:small;
	color:#666;
}

.task-note .note {
	padding:0.3em;
}

.staff {
	font-weight:bold;
}

.task-edit-note {
	height:20vh;
}

.signature-edit-note {
	height:30vh;
}

.task-prior-notes {
	height:20vh;
	overflow:auto;
	background:#eee;
}

.task .summary .topline {
	background:#ddd;
}

.task .name {
	font-weight:bold;
}

.task .people .pending {
	/*
	font-weight:bold;
	*/
	font-style:italic;
}

.task .people .done {
	font-style:italic;
	text-decoration:line-through;
}

.task .inactive {
	font-weight:bold;
}

.task-category {
	color:#4e4f4f;
	font-weight:normal;
	font-style:italic;
}

.task-pri-Urgent {
	color:red;
	font-weight:bold;
}

.task-pri-High {
	color:red;
}

.task-pri-Low {
	color:#ccc;
}

.task-header {
	margin-top:0.5em;
	padding-left:0.5em;
	/* margin-bottom: -0.5em; */
	/* margin-left: -0.9em; */
	/* margin-right: -2.1em; */
}

.detail-view .task-header {
	margin-bottom:7px;
	padding-left:5px;
}

.detail-view .task-done-header {
	background:rgba(240,255,240,1);
	
}

.detail-view .task-not-done-header {
	background:rgba(240,0,0,0.2);
}

.task-my-header {
	background:#94e889;
}

.task-filter-label {
	margin-left:0.5rem;
	margin-right:0.2rem;
	color:#999;
}

.task-sortby {
	min-width:10em;
}

.email-tasks-list {
	padding-top:10px;
}

/* task due dot */

.task-not-done {
	color:#7FDBFF;
	visibility:hidden;
}

.task-done {
	background:#2ECC40;
	color:white;
	padding:0 2px;
}

.task-almost-due {
	color:#ff851B;
}

.task-past-due {
	color:#f012BE;
}

/* Core Components. List View */

.list-view-scroll-panel {
	width: 100%;
	height:calc(100vh - 255px);
} 

.detail-view-scroll-panel {
	width: 100%;
	height:calc(100vh - 200px);
} 


.list-view {
	border-right:1px solid #ccc;
	height:
}

.list-view a {
	color: black;
}

.p-toolbar .p-button {
	margin-left:0.5em;
	font-size:small;
	background:#f8f8f8;
	padding-left:0.4rem;
	padding-right:0.4rem;
}

.p-toolbar .p-button-icon-only{
	color:#007ad9;
}

.list-view .p-toolbar, .detail-view .p-toolbar {
/*
	background:#ddd;
	min-height:51px;
	*/
}

.list-view .p-toolbar strong, .detail-view .p-toolbar strong{
/*
	font-weight:normal;
	color:#999;
	font-size:large;
	*/
}

.selected {
	/*
	border:1px solid #333;
	border: 4mm ridge rgba(170, 50, 220, .6);
	border-top: thick double #aaa;
	border-bottom: thick double #aaa !important;
	background: linear-gradient(#e0e0ff, #ffffff);
	*/
	background:#eef !important;
}

.selected .task-header {
	/*
	background:none !important;
	*/
}

/*--- new items ---*/

.task-detail-header {
	background:#eef;
	border-bottom:1px solid #ccc;
	padding:0.5em;
}

.task-email-ref-div {
	background:#f8f8ff;
	border-bottom:1px solid #ccc;
	padding:0.5em;
}

.last-activity-div {
	padding:0.3em 0.5em 0em 0.5em;
	margin-top:0.5em;
	width:600px;
	border:1px solid #ccc;
	margin-left:20px;
}

.last-user-activity-div {
	padding:0.3em 0.5em 0em 0.5em;
	margin-top:0.5em;
	width:350px;
	border:1px solid #ccc;
	margin-left:20px;
}

.last-activity-div-hidden-item {
	padding-left:27px;
}

.user-activity-list-div {
	margin-top:30px;
}

.activity-list-div-header {
	font-weight:bold;
	text-decoration:underline;
}

.p-tabview-panels {
	/*
	padding-left:0em !important;
	padding-right:0em !important;
	*/
}

.p-tabview-title {
	font-weight:normal;
}

.list-item {
	padding:0.3em 0 0.3em 1em !important;
	border-bottom:1px solid #ccc;
	margin: 0;
	margin-top:0em;
	margin-right:-0.2em;
	margin-left:-0.9em;
}

.hover-hightlight {
}
	

.hover-highlight:hover {
	/*
	background:#f4f4ff;
	*/

background: rgb(2,0,36);
background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(244,244,255,1) 0%, rgba(0,212,255,1) 100%);

background: rgb(2,0,36);
background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(248,248,255,1) 0%, rgba(224,224,255,1) 100%);
}

.hover-highlight:hover .task-header {
	background:#f4f4ff !important;
}

.notification-log-success {
	background:#94e889;
}

.notification-log-error {
	background:#ff9191;
}

.edit-task .p-grid {
	margin-bottom:0.5em;
}

.new-email-cc-div .p-button {
	background-color: #dadada;
	border: 1px solid #dadada;
	color: #333333;
	outline: 0 none;
	font-size: xx-small;
	padding:0;
}

.to-micro {
	padding:0 2px;
	margin: 0 1em 0 0;
}

.cc-button {
	background-color: #dadada !important;
	border: 1px solid #dadada !important;
	color: #333333 !important;
	outline: 0 none !important;
	font-size: xx-small !important;
	margin-top: 12px !important;
}

.dropzone {
	border:1px solid #ddd;
	border-radius: 8px;
	padding:0.25em 0.5em;
	background:#eeeef4;
	cursor:pointer;
	font-size:small;
	min-height:40vh;
	text-align:center;
}

.dropzone--isActive {
	background:#eeeeff;
}

.attachment {
	margin:0.2em 0;
}

.attachments {
	border-bottom:1px solid #999;
	padding-left:4em;
}

.attachments h5 {
	margin:0 0 0 -4em !important;
	padding:0;
}

.email-task-assign {
	background:#eee;
}

.email-task-assign-button {
	padding:0.2em 2.2em;
}

/*--- New Email ---*/

.spacer {
	min-height:0.7em;
}

.sep {
	margin-right:1em !important;
}

.iconsep .p-button-text {
	margin-left:0.7em !important;
}

.iconsepleft {
	margin-left:0.7em !important;
}

.small-sep {
	margin-left:5px !important;
	margin-right:5px !important;
}

.aline {
	margin-top:0.5em;
	border-top: 1px solid #ccc;
}

.bline {
	border-bottom: 1px solid #ccc;
}

.center {
	text-align:center;
}

.right {
	text-align:right;
}

.col-right {
	display:flex;
	justify-content: flex-end;
}

.fade {
	color:#666;
}

.fader {
	color:#aaa;
}

.grey {
	background:#eee;
}

.lightYellow {
	background:khaki;
}

.result {
	display:inline;
	line-height:4em;
	color:black;
	background:#dd8;
	font-size:1.1em;
	padding:0.6em;
}

.list-legend {
	/*
	background:#ddd;
	color:#999;
	border-bottom:1px solid #aab;
	margin-bottom:2px;
	padding-left:1.5em !important;
	*/
	margin:0.5em 0 0 -.3em;
}

.non-bold {
	font-weight:normal;
}

.p-col {
}

.p-col-undo-padding {
	margin: -0.5em;
}


.new {
	color:#ff6666 !important;
}

.new-activity-low {
	color:#fcba03 !important;
}

.new-activity-medium {
	color:#ff6666 !important;
}

.new-activity-high {
	color:purple !important;
}

.new-activity-urgent {
	color:blue !important;
}

.red {
	color:#f00;
}

.small, .small * {
	font-size:small !important;
}

.xsmall, .xsmall * {
	font-size:x-small !important; 
}

.xxsmall {
	font-size:xx-small;
}

.auto-login {
	margin: 0.5em 0 0 0;
	font-size:small;
	color:#999;
}

.auto-login .p-checkbox-icon {
	background:transparent;
	color:#ccc;
}
.auto-login .p-checkbox-box {
	border:0 !important;
	height:0 !important;
}

.profile .name a {
	font-size:x-large !important;
}

.profile .org a {
	font-weight:bold;
}

.profile .p-card-subtitle {
	font-size:xx-small;
	font-weight:normal;
}

.my-profile {
	padding:0 2em 0 0;
}
.my-profile .name {
	color:#9a9;
}
.my-profile .email {
	color:#ccc;
	font-size:small;
}
.my-profile .org {
	font-size:small;
	font-weight:bold;
	margin:0.5em 0;
	color:#999;
}

.menusep {
	margin: 0 0.75em 0 0.25em;
}

.iconlink {
	cursor:pointer;
}

.goicon {
	cursor:pointer;
	color:#66f;
}

.goicon:hover {
	font-weight:bold;
}

.oddrow {
	background:#e8e8e8;
	background:#f8f8f8;
}

.evenrow {
	background:#fff;
}

.bold {
	font-weight:bold;
}

.helpinfo {
	color:#999;
	font-size:x-small;
	font-style:italic;
}

.dummyPanel .p-panel-titlebar-toggler {
	display:none !important;
}

.mytasks-wrapper {
	height:90vh;
	padding-top:0em !important;
}

.mytasks-list {
	height:94%;
	overflow-y:auto;
}

.mytask-legend {
	padding:0 1em;
	color:#666;
	font-size:small;
}

.mytask-legend .flags {
	background:#fafafa;
	float:left;
	padding:0 0.5rem;
}

.mytask {
	border-top:1px solid #ddd;
	border-left:4px solid white;
	cursor:pointer;
}

.mytask:hover {
	background:#f4f4ff;
}

.mytask.sel {
	border-left:4px solid #99f !important;
	border-top:1px solid #ccf !important;
	border-bottom:1px solid #ccf !important;
}

.mytask.sel:hover {
	background:transparent;
}

.mytask-header {
	padding:0 1em;
}

.mytask-detail {
	margin-left:4em;
}

.mytask-detail .task-note-header {
	color:#333;
}

.mytask-menu {
	border-top:1px solid #ccf;
	background:#eef;
	padding:0.25em 1em 0.5em 1em;
	margin-bottom:0.5em;
}

.mytask-menu button {
	margin-left:2em;
}

.last-action {
	background:#eee;
	color:#333;
	font-size:small;
	font-style:italic;
	border-bottom:1px solid #ccc;
}

.task-info {
	color:#999;
	background:#eee;
	margin-right:1rem;
}

.note-action {
	display:inline;
}

.qtext {
	font-family:Courier;
	padding:0 0.25em;
	font-style:italic;
	/*
	text-decoration:underline;
	*/
	display:block;
}

.code {
	font-family:Courier,mono;
	padding:1em;
	background:#eee;
}

.note-note {
	margin-left:2em;
}

.recent-note-action {
	font-style:italic;
	color:#4e4f4f;
	margin-left:2em;
}

.task-date-updated {
	color:#999;
}

.task-status {
	color:#4e4f4f;
	display:block;
}

.mytask-header .staff{
	font-weight:normal;
}

.humanized-duration {
	padding-left:1rem;
	color:#999;
}

.normal {
	font-size:1rem;
	font-weight:normal;
}

.org-setting .p-inputtext {
	width:100%;
	height:auto;
}

.room-field {
	border-top:1px dotted #ddd;
}

.room-field .p-dropdown {
	min-width:12em;
}

.room-profile-mini {
	height:10em;
	overflow:auto;
}

.room-profile .p-button {
}

.room-profile pre {
	margin:0;
	font-family: initial;
	background:#eee;
	padding:0.5em;
}

.room-profile .p-input-textarea {
	height:4em;
}

.profile-settings-card {
	background-color:#E8F0FE !important;
}

.new_room_category {
	margin-top:0.5em;
	border-top:2px solid #bbb;
}

.contact_edit_button {
	margin-left:1em !important;
}

.contact_edit_cancel_button {
	margin-left:1em !important;
}

.feedback-list {
	min-width: 600px;
}

.feedback-list div .p-col-1 {
	padding: 10px 20px 15px 20px;
	border-right: 1px solid gray;
	border-bottom: 1px solid gray;
	border-left: 1px solid gray;
}

.feedback-list div .p-col-2 {
	padding: 10px 20px 15px 20px;
	border-right: 1px solid gray;
	border-bottom: 1px solid gray;
	border-left: 1px solid gray;
}

.feedback-list .first-row div {
	border-top: 1px solid gray;
	background: #d7dbe8;
	font-weight: bold;
}

.feedback-list .item-row div {
	border-top: 1px solid gray;
	background: #fff;
}

.feedback-view {
	min-width: 600px;
}

.feedback-view div .p-col-1 {
	padding: 10px 20px 15px 20px;
	border-bottom: 1px solid gray;
	border-left: 1px solid gray;
	border-top: 1px solid gray;
	background: #eef;
}

.feedback-view div .p-col-2 {
	padding: 10px 20px 15px 20px;
	border-bottom: 1px solid gray;
	border-left: 1px solid gray;
	border-top: 1px solid gray;
	background: #eef;
}

.feedback-view div .p-col-6 {
	padding: 10px 20px 15px 20px;
	border-right: 1px solid gray;
	border-bottom: 1px solid gray;
	border-left: 1px solid gray;
	border-top: 1px solid gray;
	background: #fff;
}

.feedback-view .first-row div {
	border-top: 1px solid gray;
}

.feedback-update {
	min-width: 600px;
}

.feedback-update .header {
	font-weight: bold;
	font-size: large; 
}

.feedback-update div .p-col-2 {
	padding: 10px 20px 15px 20px;
	border-bottom: 1px solid gray;
	border-left: 1px solid gray;
	border-top: 1px solid gray;
	background: #eef;
}

.feedback-update div .p-col-6 {
	padding: 10px 20px 15px 20px;
	border-right: 1px solid gray;
	border-bottom: 1px solid gray;
	border-left: 1px solid gray;
	border-top: 1px solid gray;
	background: #fff;
}

.feedback-update .first-row div {
	border-top: 1px solid gray;
}

.display-feedback-details {
	white-space: pre-line;
}

.feedback-list-item {
	cursor: pointer;		
}

.feedback-list-item:hover {
	background: #eef !important;	
}

.latest-features {
	height: calc(100vh - 100px);
	overflow-y: scroll;
}

.latest-features-version-header {
	font-size: small;
	font-size: 1.2em;
	border-top: 1px solid #999;
	padding: 1em 0 1em 0;
}

.latest-features-version-header-date {
	font-weight: normal;
}

.latest-features-version-item {
	padding: 0 0 3em 0;
}

.latest-features-accordian-header {
	font-size: small;
}

.rooms-show-only-active-checkbox {
	margin-top: 0em !important;
	margin-bottom: 0em !important;
}

.room-category-dropdown {
}

/* Also used in Feedback?? */

.full-height {
	height:calc(100vh - 80px);
	display:flex;
	flex-direction: column;
}
.fill-height {
	flex:1 1 auto;
	overflow-y:auto;
}
.reg-height {
	flex:0 1 auto;
}

/* Chat */


.room-chat {
	height:calc(100vh - 150px);
	display:flex;
	flex-direction: column;
}

.org-chat {
	height:calc(100vh - 80px);
	display:flex;
	flex-direction: column;
	padding:0 0.5em;
	background:white;
}

.chat-history {
	flex:1 1 auto;
	overflow-y:auto;
	overflow-x:hidden;
}

.chat-write {
	flex:0 1 auto;
	background:#f4f4f4;
	padding:0.7em 0 0 0;
}

.chat-to-div {
	padding-left:0;
}

.chat-input {
	width:100%;
}

.chat-members {
	height:1.5em;
	border-bottom:1px dotted #666 !important;
	margin-bottom:0.6em;
}

.chat-member-legend {
	font-size:small;
	font-weight:bold;
	color:#999;
}

.chat-member-on {
	color:green;
	margin:0 0.25em;
}
.chat-member-off {
	color:#999;
	margin:0 0.25em;
}

.chat-by {
	font-size:small;
	color:#666;
}

.mobile-frame .chat-by {
	color:#999;
}

.chat-new {
	font-weight:bold;
	color:#933;
}

.chat-date {
	font-size:small;
	color:#999;
}

.chat-note {
	font-size:small;
}

.chat-to-all {
	color:#ddd;
	font-size:small;
}

.chat-to-label {
	color:#999;
	font-size:x-small;
	margin-right:0.5em;
}

.chat-private {
	background:#eee;
	border-radius: 8px;
}

.chat-private .chat-to {
	font-weight:bold;
	font-size:x-small;
	padding-left:1.25em;
}

.sidebar {
	position:fixed;
	height:90vh;
	width:25vw;
	overflow:auto;
}

.warn {
	background: #ff6;
	padding:0.5em;
}

.online {
	color:green;
	font-weight:bold;
}

.offline {
	color:#8a3324;
}

.idle {
	color:orange;
	font-weight:bold;
}

.cluster {
	border:1px solid #ccc;
	border-top:3px solid #bbb;;
	box-shadow:6px 6px 2px #eee !important;
}
.pad {
	padding:1em;
}

.being-moved {
	background:#cec;
}

.being-moved .legend {
	background:#cec;
	text-align:center;
	font-weight:bold;
	color:#c6c;
}

.being-sent {
	background:#cec;
}

.being-sent .legend {
	background:#cec;
	text-align:center;
	font-weight:bold;
	color:#c6c;
}

.se_error .legend {
	background:#e66;
	font-size:small;
	color:white;
	font-weight:normal;
}

.se_warn .legend {
}

.get-more-list-header {
	padding:1em;
}

.nest-0 {
}

.nest-1 {
	margin-left:1em !important;
}
.nest-2 {
	margin-left:1.5em !important;
}
.nest-3 {
	margin-left:1.7em !important;
}
.nest-4 {
	margin-left:1.8em !important;
}
.nest-5 {
	margin-left:1.9em !important;
}
.nest-6 {
	margin-left:2.0em !important;
}
.nest-7 {
	margin-left:2.0em !important;
}
.nest-8 {
	margin-left:2.0em !important;
}
.nest-9 {
	margin-left:2.0em !important;
}

.linked-tasks {
	padding:0.5em 0 0.7em 0;
	font-size:small;
	color:#aaa;
	background:#f8f8ff;
	text-align:right;
}

.fade-link {
	color:#99f;
}

.person-na {
	color:orange;
}

.person-available {
	color:green;
}

.person-kind {
	color:#99c;
	font-size:small;
	font-style:italic;
	padding-left:1em;
}

.person {
	background:#f4f4f4;
	margin:0.5em;
	border-radius: 8px;
	cursor:pointer;
}

.create-room .person.ptaken {
	background:#ddd;
}

.create-room .person.ptaken .name {
	text-decoration: line-through;
}
.create-room .person.ptaken .email {
	text-decoration: line-through;
}

.create-room .person.pfree {
	border:1px solid #ddd;
}

.person .name {
	font-size:1.2em;
	text-overflow: ellipsis;
	overflow:hidden;
	margin-bottom:4px;
}

.person .email {
	color:#666;
	font-size:small;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow:hidden;
}

.person-name-not-set {
	font-weight:normal;
	font-style:italic;
	color:#999;
}

.person .role {
	color:#999;
	padding:4px 0;
}

.person .comment {
	font-style:italic;
	color:#666;
}

.person-name-edit {
	width:13em;
	font-size:small;
}

.person .p-inplace {
	height:1em;
}

.person:hover {
	background:#ddf;
}

.persons-legend {
	font-size:small;
	color:#99c;
	text-align:center;
}

.name-edit-icon {
	color:#999;
	float:right;
}

.folder-name {
	color:#999;
	text-align:right;
	padding-right:0.5em;
}

.full-email .p-card {
	border:1px solid #999;
}

.full-email .p-card-title {
	border-bottom:1px solid #ddd;
	text-align:right;
	font-size:1.1em;
	display:none;
}

.email-to-mode .p-selectbutton * {
	font-size:small;
}

.to-small-extra {
	color:#999;
}

/* ical calendar event display */

.ical table {
	background:#eef;
}

.ical table th {
	text-align:left;
	font-size:small;
	font-weight:normal;
	color:#666;
	vertical-align:top;
	padding:0.25em 1em;
}

.ical table tr.ical-action {
	font-size:small;
	color:#666;
}

.ical table td ul {
	margin:0;
}

.ical dl {
	background:#efe;
	padding:0.5em;
}

.ical dt {
	margin:1em 0 0 0;
}

.ical .ical-hint {
	font-size:small;
	text-align:right;
	padding:0.25em 0;
	background:#eee;
}

.ical-name {
}

.ical-email {
	margin:0 0 0 1em;
	font-size:x-small;
}

.ical-tz {
	margin:0 0 0 1em;
	font-size:small;
	color:#666;
}
	

/*
.toolbar-dropdown.p-dropdown {
	margin-left:0.5em;
	background:#eee !important;
}
.toolbar-dropdown.p-dropdown * {
	font-size:x-small;
	background:#eee;
}
*/
.toolbar-label {
	font-size:x-small;
}

.task-toolbar * {
	font-size:small;
}

.pending-options {
	margin-left:0.2rem;
}
.pending-options * {
	font-size:small;
}

.mailbox-options {
	width:9em;
}

.page-options {
	margin-left:0.7rem;
}

.page-options * {
	font-size:small;
}
/* --- bulk-org --- */

.admin-links ul {
	padding:1em;
}

.admin-links li {
	line-height:1.5em;
}

.bulk-org .legend {
	background:#d4d4e4;
	margin:1em 0;
}

.bulk-org .rsname {
	font-size:x-small;
	margin:0 1em 0 0;
}

.bulk-org .rcname {
	font-size:x-small;
	margin:0 1em 0 0;
	font-style:italic;
	color:#999;
}

.bulk-org .p-dropdown {
	width:100%;
}

.bulk-org .back {
	font-weight:normal;
	font-size:small;
}

.bulk-room {
	margin:0.5em 0;
	background:#ddf;
}

.bulk-room-legend {
	font-weight:bold;
	color:#666;
}

.bulk-sep {
	border-top:3px solid white;
	padding:0.5em 0;
}

.bulk-room-count {
	margin:1em 0;
	color:#999;
}

.bulk-rooms-gen {
}

.bulk-room-example {
	word-break:break-all;
	white-space:pre-line;
}

.bulk-info-item {
	margin:0.3em 0.5em;
}

.bulk-info-prop {
	color:#999;
	font-style:italic;
	margin-right:0.5em;
	white-space:nowrap;
}

.bulk-info-val {
	white-space:nowrap;
	color:#666;
}

/* --- merge clients --- */

.merge-clients .cuser {
	border:1px solid #ddd;
	cursor:pointer;
	margin:1em 0;
}

.merge-clients .sel {
	border:2px solid #333;
	background:#eee;
}

.backbar {
	border-bottom:1px solid #ddd;
	max-height:4rem;
	margin-bottom:2rem;
}

.backbar * {
	align-self:center;
}

.backbar .info {
	color:#999;
	font-size:x-small;
	text-align:right;
}


.merged-client {
	border:1px solid #ddd;
	box-shadow: 5px 5px 5px #ccc;
	margin:0 0 1em 0;
	text-align:center;
}

.merged-client .instr {
	font-size:x-small;
	color:#fb0;
	border-bottom:1px solid #ddd;
	margin:0 0 1rem 0;
}

.merged-client .name {
	width:100%;
}

.merge-emails {
	text-align:left;
	margin:1em 2em;
}

.merge-emails .p-inputtext {
}

/* --- checklists --- */

.checklist {
	width:18rem;
	margin:1rem 2rem 1rem 1rem !important;
}

.checklist .p-inputtext {
	width:100%;
}

.ckl-name {
	font-weight:bold;
	background:#ddd;
	padding:0.5rem;
}

.checklist ol {
	margin:0;
	padding:0 0 0 2rem;
}

.checklist li {
	margin:0.5rem;
	cursor:pointer;
}

.flex-grow {
	flex-grow: 4;
}

.checklist-logs {
	min-width:30vw;
	max-height:40vh;
}

.checklist-logs .p-dialog-content {
	display:block;
}

.checklist-logs .p-confirm-dialog-message {
	display:block;
	margin-top:-1rem;
}

/* --- tags --- */

.tasktab {
	height:calc(100vh - 11em);
}

.add-tag .p-button {
	margin:0 0.25em 0.25em 0;
}

.add-tag .desc {
	font-size:small;
	font-style:italic;
	color:#999;
}

.preset {
	margin:0 0.5em;
	background:#eef;
	border-radius:6px;
	padding:2px 5px 2px 10px;
	line-height:2em;
	cursor:pointer;
}

.preset span {
	white-space:nowrap;
}

.preset:hover {
	background:#ccf;
}

.add-tag .p-dropdown {
	border-color:#eee;
}

.add-tag .p-dropdown * {
	font-size:small;
	color:#999;
}

.tags {
	margin:0 0 0.75em 0;
	max-height:12em;
	overflow:auto;
}

.tag {
	margin:0.2em 0;
}

.tag-done {
	background:#eee;
}

.tag-others {
	background:#fffff2;
}

.tag-mine {
	background-image:linear-gradient(to right, #efe, #71ffe0);
}

.tag-icon {
	color:#999;
}

.tag-High {
	color:#900 !important;
}

.tag-Urgent {
	color:#f00 !important;
}

.tag-Low {
	color:#008 !important;
}

.tag-from {
	color:#999;
	font-size:x-small;
}

.tag-to {
	font-size:small;
}

.tag-note {
	font-size:small;
}

.tag-mine .tag-note {
	font-weight:bold;
}

.tag-action-done {
	color:#aaa;
}

.tag .p-button {
	margin:0;
}

.untagged {
	background:#ffb;
}

.notag {
	color:#f99;
}

.tagtab { 
	background:#eee;
}

.tagtab .p-scrollpanel-content {
	max-width:100%;
	overflow-x: hidden !important;
}

.mytags {
	max-height:calc(100vh - 15em);
	overflow:auto;
	background:#fff !important;
}

.mytags-room {
	text-overflow:ellipsis;
	white-space:nowrap;
	overflow:hidden;
	color:#666;
}

.mytags-legend {
	background:#eee;
	font-weight:bold;
	color:#666;
	width:calc(100% - 3.2em);
	margin-left:0.05em;
}

.mytags-row {
	margin-top:0em !important;
}

.mytags-row div {
	padding:0.5em 0em 1em 1em;
	margin:0em 0em 0em 0em;
}

.mytags-row:hover {
	color:blue !important;
	background:#eef !important;
}

.mytags-divide {
}

.evenrow .mytags-env-info {
	background-image:linear-gradient(to bottom right, #ddd, #fff);
}

.oddrow .mytags-env-info {
	background-image:linear-gradient(to bottom right, #ccc, #eee);
}

.mytags-note {
	padding-right:1em !important;
}

.mytags-by {
	color:#999;
}

.mytags-person {
	color:#666;
}

.mytags-env-from {
	font-weight:bold;
	background-image:linear-gradient(to bottom right, #ddd, #fff);
}

.mytags-env-subject {
	font-size:small;
	font-weight:normal;
}

.mytags-date {
	color:#999;
	font-size:small;
}

.mytags-room-header {
	background:#eef;
	padding:5px 0px 5px 10px;
	font-weight:bold;
	margin-top:0em;
	border:1px solid #ddf;
}

.mytags-item-header {
	color: #888;
}

.due-past {
	background-image:linear-gradient(to right, #fee, #fcc);
}

.due-today {
	background-image:linear-gradient(to right, #fee, #ffc);
}

.sort-by-select-button .p-button {
	border: 1px solid #aaa;
}

/* env */

.env-attachment-list {
	font-size:small;
	margin-left:0.5em;
	margin-bottom:0.5em;
}

.env-attachment {
	margin-right:0.5em;
}

.room-category-inplace {
	min-height:0em;
}

.room-category-inplace.inplace-active {
	min-height:8em;
}

.room-category-inplace .p-dropdown-item {
	font-size:small;
}

.inline-edit .p-button {
	margin: 0 0 0 0.6em;
	font-size: xx-small;
}

.edit-room-category-icon {
	margin-left:1em;
	color:#aac;
	font-size:x-small !important;
}

.edit-room-category-spacer {
	width:200px;
}

.room-categories .p-inputtextarea {
	height:20em;
	width:100%;
}

.recip {
	display:inline;
	margin:0.5em;
	background:#f8f8ff;
	padding:2px;
	cursor:pointer;
}
.recip .remail {
	font-size:small;
	color:#666;
}
.recip .rkind {
	font-size:small;
}

.tos {
	margin:0.5em 0;
}

.tos-row {
	height:4em;
}

.mobile-home .pi-fw {
	color:#ddd;
}

.mobile-compose {
	margin:0 1em;
}

.mobile-compose .tos-row {
	height:auto;
}
.mobile-compose .to-mini {
	margin:0 0.5em 0.5em 0;
}

.mobile-compose .p-editor-toolbar {
	display:none;
}
.mobile-compose .p-editor-container {
	border-top:1px solid #ccc;
}

.wide-input .p-inputtext {
	width:100%;
}

.to-mini {
	margin:0 0.5em 0 0;
	background:#f8f8ff;
	cursor:pointer;
}

.to-mini:hover .tremove {
	color:#00f;
	font-weight:bold;
}

.cc-mini {
	margin:0 !important;
}

.to-Client {
	background:#dfd;
}

.to-Staff {
	background:#dff;
}

.to-Connection {
	background:#ddf;
}

.to-Manual {
	background:#edd;
}

.to-Contact {
	background:#cdd;
}

.to-undefined {
	background:#dcc;
}

.to-mini .tname {
	font-size:small;
}

.to-mini .temail {
	font-size:x-small;
	padding:0 2px;
}

.tkind {
	font-size:x-small;
	float:right;
	color:#999;
}

.to-mini .tremove {
	float:right;
	padding:0 0.25em;
	color:#bbb;
	cursor:pointer;
}
.to-mini .tremove:hover {
	color:#00f;
}

.first-to-mini {
	background: #ff9 !important;
}

.tremove-all {
	margin-left:4em !important;
}

.choose-room .choices {
	height:12em;
	overflow:auto;
	border:1px solid #eee;
	cursor:pointer;
}
.choose-room .choices div {
	padding:2px;
	border-bottom:1px solid #f4f4f4;
}
.choose-room .choices div:hover {
	background-color:#eef;
}

.freeform-to {
}

.freeform-choices {
	position:fixed;
	border:1px solid #ccc;
	height:12em;
	min-width:10em;
	background:white;
	overflow:auto;
	z-index:10;
}

.freeform-choices .to-mini {
	margin-bottom:0.5em;
}

.freeform-to .help {
	font-size:x-small;
	color:#999;
	border-top:1px solid #ddd;
	margin-top:4em;
}

.show-attach {
	margin:2em 1em 0 0;
}

.attach-item {
	margin:0;
	padding:0.1em;
	border-bottom:1px solid #ddd;
	border-top:1px solid #ddd;
}

.attach-item:hover {
	background:#eef;
}

.at-size {
	font-size:small;
	color:#999;
}

.at-rm .p-button{
}

.smart-name {
	display:inline-block;
	margin-right:1em;
	position:relative;
}

.smart-hover {
	display:none;
	position:absolute;
	top:0.8em;
	padding:0.5em;
	background:#666;
	border-radius:8px;
	color:white;
	opacity:1;
	z-index:1;
	font-size:1rem;
	font-weight:normal;
}

.smart-name:hover {
	background:black;
	color:white;
}
.smart-name:hover .smart-hover {
	display:inline;
}

.mobile-frame .smart-name:hover .smart-hover {
	display:none;
}

.smart-left {
	right:0;
}
.smart-right {
	left:0;
}

.sname {
	font-weight:bold;
}

.semail {
}

.skind {
	text-align:right;
	font-size:small;
	border-top:1px solid #999;
}

.s-kind {
	background:#557 !important;
}
.c-kind {
	background:#575;
}
.x-kind {
	background:#755;
}
.u-kind {
	background:#666;
	min-width:12em;
}

.smart-name a {
	color:white;
}

.smart-name a:hover {
	color:white;
	text-decoration:underline;
}

.sn-edit {
	text-align:right;
	border-top:1px solid #666;
}

.staff-list {
	background:white;
}
.staff-alert {
	text-align:right;
	margin:0.5em 0;
}

.smart-detail {
	font-size:small;
}
.smart-detail .pi-fw {
	margin-right:0.5em;
}
.smart-detail div {
	margin:0.25em 0;
}

.other-rooms-icon {
	margin:0 0 0px 1em;
	position:relative;
	cursor:pointer;
}

.other-rooms-panel {
	display:none;
	position:absolute;
	top:1em;
	right:0;
	background:white;
	border:2px solid #ccc;
	color:black;
	width:300px;
	max-height:300px;
}

.other-rooms-panel .p-grid {
	margin:0.5em;
}

.other-rooms-icon:hover .other-rooms-panel {
	display:block;
}

.other-rooms-legend {
	font-size:small;
	text-align:center;
	background:#ddd;
	color:#666;
	padding:3px 0;
}

.recent-room-header {
	background:#eee;
	font-weight:bold;
	color:#666;
	padding:5px 0px 10px 0px;
}

.recent-room {
	cursor:pointer;
	padding:5px 0px 10px 0px;
}

.recent-room:hover {
	color:blue;
	background:#eef !important;
}

.recent-draft-header {
	background:#eee;
	font-weight:bold;
	color:#666;
	padding:5px 0px 10px 0px;
}

.recent-draft {
	cursor:pointer;
	padding:5px 0px 10px 0px;
}

.recent-draft:hover {
	color:blue;
	background:#eef !important;
}

.recent-draft-room {
	font-weight:bold;
}

.recent-draft-date {
	color:#999;
	font-size:x-small;
}

.online-icon {
	margin:0 0 0px 1em;
	position:relative;
	cursor:pointer;
}

.online-panel {
	display:none;
	position:absolute;
	top:1em;
	right:0;
	background:white;
	border:2px solid #ccc;
	color:black;
}

.online-panel .p-grid {
	margin:0.5em;
}

.online-icon:hover .online-panel {
	display:block;
}

.online-cols-2 {
	width:12em;
}

.online-cols-3 {
	width:18em;
}

.online-cols-4 {
	width:24em;
}

.online-cols-5 {
	width:30em;
}

.online-item-online {
	color:green;
	font-weight:bold;
}

.online-item-idle {
	color:blue;
	font-style:italic;
	letter-spacing:1px;
}

.online-item-offline {
	color:brown;
}

.online-legend {
	font-size:small;
	text-align:center;
	background:#ddd;
	color:#666;
	padding:3px 0;
}

.menutabs .p-tabview-panels {
	margin:0 !important;
	padding:0 !important;
	border:0 !important;
	background: rgb(238,241,246) !important;
}

.menutabs .p-tabview-panel {
}

.bulk-email-list-title {
	font-size:16px;
	font-weight:bold;
	margin-bottom:10px;
}

.bulk-email-list-tab {
	min-height:500px;
	padding-top:20px;
}

.bulk-email-section {
	padding-left:10px;
}

.bulk-email-room-list-div {
	border:1px solid #ccc !important;
	width:90%;
	padding-right:10px;
}

.bulk-email-room-list {
	height:200px !important;
	max-height:200px !important;
	padding:10px;
}

.bulk-email-selected-room-list {
	height:455px !important;
	max-height:455px !important;
	padding:10px;
}

.bulk-email-input {
	width:90%;
}

.bulk-email-input-small {
	width:73%;
}

.bulk-email-input-extra-small {
	width:67%;
}

.bulk-email-room-name {
	padding-left:0px !important;
}

.excluded-bulk-email-room-name {
	padding-left:0px !important;
	color: #AAA;
}

.bulk-email-secondary-button {
	color: #444 !important;
	background-color: #CCC !important;
	border: 1px solid #CCC !important;
}

.bulk-email-error-div {
	white-space: pre-wrap;
	color: red;
}

.bulk-email-search-match {
	background-color: #b7d8b7;
}

.mini-list {
	max-height:60vh;
}

.mini-sel {
	background:white;
}

/* -- My Rooms  -- */

.myrooms {
	overflow:hidden;
}

.myrooms .pane {
	overflow:auto;
	height:calc(100vh - 80px);
}

.myrooms-sortby {
}

.myrooms-sortby .p-dropdown {
}

.myrooms-sortby * {
	font-size:small;
	color:#999;
}

.myrooms .sel {
	background-image:linear-gradient(to right, #eef, #ccf);
}

.myrooms-clients {
	margin:0.5em 0 0 2em;
}

.myrooms-clients .name {
	color:#666;
	font-weight:bold;
}
.myrooms-clients .email {
	color:#999;
	font-size:small;
}


.sel-list-item {
	background-image:linear-gradient(to right, #eef, #ccf) !important;
}

.rooms-panel {
	/*
	border:1px solid #ccc;
	border-radius:4px;
	box-shadow: 3px 3px 3px #ccc;
	padding:0px;
	margin:0;
	*/
	background:white;
}

.rooms-split-panel {
	border:1px solid #ccc;
	border-radius:4px;
	box-shadow: 3px 3px 3px #ccc;
	padding:1rem;
	margin:0 1rem;
}


.rooms-legend {
}

/*
.rooms-panel h3 {
	text-align:center;
	margin:0;
	padding:0;
}
*/

.rooms-panel .botborder {
	border-bottom:1px solid #ccc;
}

.rooms-menubar {
	padding:0 0 0.5rem 0;
}

.rooms-checkboxes {
	background:#f4f4f4;
	margin:0.5rem 0.4rem;
}

.room-setting-icon {
	color: #aaa;
}

.room-setting-icon .p-checkbox {
	margin-right:3px;
	margin-left:1.5rem;
}

.room-setting-icon-prominent {
	color:#ff6666 !important;
}

.rooms-search {
	width:16rem;
}

.rooms-search .p-inputtext {
	width:10rem;
	font-size:small;
}

.rooms-search .p-button {
	font-size:small;
}

.m-cat {
	color:#999;
	font-style:italic;
	font-size:x-small;
	float:right;
	margin-right:1.5em;
}

.lite-hdr {
	color:#aaa;
}

.room-list {
	cursor:pointer;
	overflow-y:auto;
	overflow-x:hidden;
	padding-top:7px;
}

.room-list.preview {
	max-height:calc(100vh - 215px);
}

.room-list.no-preview {
	max-height:calc(100vh - 170px);
}

.empty-room-list {
	padding:10px;
}

.rooms-icons {
	width:14rem;
}

.rooms-category {
	width:20rem;
}

.room-detail {
	margin-top:0.5em;
	overflow-y:auto;
	overflow-x:hidden;
	max-height:calc(100vh - 145px);
}

.room-detail .sidehdr {
	font-weight:bold;
	text-align:right;
}

.room-detail .tab-name {
	font-weight:bold;
	color:#666;
	font-size:x-small;
}

.room-detail .field-name {
	font-weight:bold;
	font-size:small;
	color:#aaa;
}

.room-detail .newer {
	font-weight:bold;
}

.room-detail .term {
	font-weight:bold;
	font-size:x-small;
	color:#999;
}

.field-info {
	white-space: pre;
}

.room-settings .my-notes, .room-detail .my-notes {
	white-space: pre-wrap;
}

.room-settings-field {
	white-space: pre-wrap;
}

.mod-category .p-button {
	margin:0 0 0 1em;
}

.mod-category.edit {
	min-height:10em;
}

.act {
	cursor:pointer;
	margin:0 0 0.25em 0;
	background-image:linear-gradient(to bottom, #eef, #fff);
}

.act:hover {
	background:#eef;
}

.act-client {
	color:black;
	margin-bottom:0.5em;
}

.act-client .emails {
	font-size:small;
	padding-left:1em;
	color:#666;
}

.act-staff {
	margin:0 0.5em 0 0;
	background:#f8f8f8;
	padding:0 2px 0 0;
}

.act-staff.act-me {
	background:#ffd;
	font-weight:bold;
	padding:1px 4px 0 0;
}

.act-system {
	color:#999;
	font-size:small;
}

.act-what {
}

.act-tab {
	border-top:2px solid #eee;
}

.act-verb {
	margin-right:1em;
}

.act-summary {
	font-style:italic;
}

.unread-tag-date {
	color:#870808;
	font-weight:bold;
}

.unread-tag-room {
	color:#870808;
}

.detail-pane-icon {
	margin-top:7px;
}

.svg-icon {
	height: 12px;
	width: 18px;
	font-size:9px;
	background:#fff465;
	fill: white;
}

.svg-icon2 {
	height: 18px;
	width: 16px;
	font-size:9px;
	background:#fff4d5;
	background:#cd5c5c;
	padding:1px;
	fill: white;
	margin-bottom:-4px;
	padding-bottom:-4px;
}

.tab-hdr {
	font-weight:bold;
}

.new-0 {
	visibility:hidden;
}

.new-1 {
	fill: #ff6699;
}

.new-2 {
	fill:white;
}

.tab-new-undefined {
}

.tab-new-0 {
}

.tab-new-1 {
	color:green;
}

.tab-new-2 {
	color:red;
}

.newtab-undefined {
}

.newtab-0 {
}

.newtab-1 {
	color:green;
}

.newtab-2 {
	color:red;
}

.act-email {
	font-size:0.85em;
}

.act-email .p-col {
	padding:0;
	margin:0;
}

.act-label {
	color:#999;
}

.act-email .act-verb {
	font-weight:bold;
	font-size:normal;
}

.act-email-person {
	background:#eee;
	padding:0 4px;
	margin:0 0.5em 0 0;
}

.act-email-me {
	font-weight:bold;
	background:#ffd;
}

/*   */

.tag-room {
	font-size:small;
	color:#999;
}

.task-room {
	font-size:small;
	color:#999;
}

.mini-rarr {
	float:right;
	margin-right:0.8em;
	color:#999;
}

.mini-rarr:hover {
	color:blue;
}

.mini-search-bar {
	display:inline-block;
	margin-top:1em;
}

.mini-search-bar .p-grid {
}

.mini-search-bar .p-inputtext{
	width:80%;
}


/*----- My Lists ------*/

.listtab * {
	font-size:small;
}

/*----- sms ------*/

.no-smstel {
}

.smstel-edit {
	margin-left:1em;
	color:#aac;
	font-size:x-small;
}

.smstel-set {
	color:orange;
}

.smstel-help {
	background:#eee;
	font-size:small;
	padding:0.5em;
}

.new-sms-tels span {
	margin-right:1em;
	font-size:small;
	border-bottom:1px dotted #999;
}

.new-sms-to .p-button {
	margin-right:0.25em;
}

.sms_email {
	font-size:small;
	color:#666;
}

.sms-wrap {
	margin:2em;
}

.sms-wrap-hdr {
	background:#eef;
	padding:0.5em;
}

.sms-wrap .p-card-content {
	background:white;
	padding:1em;
}

.def-dialog {
	width:50vw;
	height:70vh;
}

.auto-move {
	margin-left:1em;
	background:#eef;
	color:blue;
	padding:2px;
}
.auto-move-summary {
	margin:0 0.5em;
	background:#eef;
	color:blue;
	padding:2px;
}

.list-search .p-inputtext {
	width:10em;
	margin-right:0.5em;
	font-size:small;
}

.list-search .p-button {
	margin-right:0.5em;
	margin-bottom:5px;
	width:1.5em;
	height:1.5em;
}

.edit-connection .label {
	color:#999;
}

.edit-connection .p-inputtext {
	width:100%;
}

.remove-connection {
	float:right;
}

.person-search.p-inputtext {
	width:16em;
}

.my-room-sidebar-icons {
	font-size:xx-small;
}

.my-room-sidebar-icons i {
	font-size:12px !important;
}

.tag-viewed-indicator {
	font-size:x-small;
}

.my-notes-display {
	white-space: pre-line;
	background: #f8f8f8;
	padding: 0.5em;

	max-height:5em;
	overflow:auto;
}

.room-setting-update-icon {
	color: black;
}

.room-detail-icons {
	color:black;
	text-align:right;
	cursor:pointer;
}

.room-setting-icons i {
	cursor:pointer;
}

.is-sms-icon {
	font-size: large;
}

.display-attached-images-div {
	text-align: right;
	padding-right: 1.5em;
}

.display-attached-images-div .p-button-text {
	padding: 0.1em 0.3em !important;
	font-size: x-small;
}

.enable-links-button {
	margin-left: 1em !important;
	font-size:small;
	padding:3px;
}

.email-buttons {
	display: flex;
	flex-direction: row-reverse;
}

.email-buttons .p-button {
	background: #ffffff;
	border: 1px solid #ced4da;
	border-right-color: rgb(206, 212, 218);
	border-right-style: solid;
	border-right-width: 1px;
	color: #495057;
	transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.email-buttons .p-button:hover {
	background: #eeeeee !important;
}

.prewrap {
	white-space: pre-wrap;
}

.ai-test-panel {
}

.ai-test-panel-output {
	height: calc(100vh - 290px);
	width: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	padding-left: 10px;
	padding-right: 10px;
	border: 1px solid #DDD;
	font-size: small;
}

.ai-test-panel-output-item {
       	border-bottom: 1px solid #DDD;
	margin-bottom: 10px;
	margin-top: 10px;
}

.ai-test-panel-output-item-light {
	color: #999;
}

.ai-test-progress-spinner div {
	width: 16px;
	height: 16px;
}

.ai-test-input {
	width: 100%;
	resize: none;
}

.ai-test-input-button {
	padding-top: 17px;
	padding-left: 10px;
}

.ai-assist-textarea {
	resize: none;
	width: 100%;
	border-radius: 5px;
	border:1px solid #ddd;
	font-size: 13px;	
	background:#fafafa;
	min-height: calc(50vh - 155px);
	height: calc(50vh - 155px);
	max-height: calc(50vh - 155px);
}

.ai-assist-buttons-section {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.ai-assist-buttons .p-button {
	background: #ffffff;
	border: 1px solid #ced4da;
	border-right-color: rgb(206, 212, 218);
	border-right-style: solid;
	border-right-width: 1px;
	color: #495057;
	transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.ai-assist-buttons .p-button:hover {
	background: #eeeeee !important;
}

.ai-gen-label {
	padding-top:10px;
	color:#666;
	font-style:italic;
	font-size:small;
}

.ai-gen-hint {
	border:1px solid #ddd !important;
	color:#333;
	font-size:small;
	height:2.1rem;
}

.find-emails-wrapper {
}

.find-emails-wrapper .fe-center {
	align-self: center;
}

.find-emails-wrapper .fe-bottom {
	align-self: end;
}

.find-emails-wrapper .fe-label {
	font-weight:bold;
}

.find-emails-input {
	display: inline;
}

.find-emails-input-dropdown {
	width: 12em;
}

.find-emails-input-text {
	width: 30em;
}

.find-emails-results {
	overflow-y: scroll;
	height: calc(100vh - 200px);
}

.find-emails-header-div {
	margin-right: 0;
	margin-top: 0;
}

.find-emails-header-col {
	background: #eef;
	border-top: 1px solid #dde;
	border-bottom: 1px solid #dde;
	font-weight: bold;
}

.find-emails-header-col-left {
	padding-left: 16px;
}

.find-emails-env-col-ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.find-emails-env-room-list {
	padding-left: 18px;
	margin-top: 0;
	margin-bottom: 0;
}

.find-emails-label {
	padding-bottom: 0;
}

.find-emails-help-text {
	font-size: xx-small !important;
	text-align: right;
	padding-top: 13px;
	font-weight: normal;
	color: #666;
}


/* ---- Mobile Only below ---- */

/* -- mobile header/toolbar/body, title/back/.. -- */

.mobile-wrap {
}

.mobile-wrap i.pi {
	/* icons are slightly higher than text. fix it */
	position:relative;
	top:2px;
}

.mobile-frame {
	display: flex;
	flex-flow: column nowrap;
	max-height:100vh;
	font-size:16px;
}

.mobile-header {
	flex:none;
	min-height:2.5em;

	display: flex;
	flex-flow: row nowrap;
	justify-content:space-between;
	align-items:center;

	padding:0 0.8em;
	border-bottom:1px solid #999;
	background-image:linear-gradient(to right, #00d4ff, #0399d8);
}

.mh-back {
	flex: 0 0 8em;
	position:relative;
}

.mh-back .pi-chevron-left {
	/*
	font-size:small;
	font-weight:bold;
	*/
}

.mh-title {
	flex: 1 1 10em;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow:hidden;

	font-weight:bold;
}

.mh-title i {
	margin-right:0.2em;
}

.mh-menu {
	flex: 0 0 auto;
}

.mobile-body {
	display: flex;
	flex:auto;
	overflow-y: auto;
}

.mobile-body-inner {
	display:block;
	width:100%;
}

.mobile-toolbar {
	flex: 0 0 auto;

	/*
	display: flex;
	flex-flow: row nowrap;
	justify-content:space-between;
	align-items:center;
	*/
	text-align:center;

	line-height:3em;

	background-image:linear-gradient(to right, #f5f7fa, #c3cfe2);
	border-bottom:1px solid #ccc;
}

.mobile-toolbar .fa-fw, .mobile-toolbar .pi-fw {
	font-size:x-large;
	padding:0 1em;
}

.mobile-toolbar .mobile-adjust  {
	margin-bottom: 0.4rem !important;
}

.mobile-toolbar .mobile-adjust .pi-fw {
	font-size:1rem;
	padding:0;
}

.mobile-toolbar .rooms-menubar {
}

.mobile-toolbar .rooms-menubar .rooms-checkboxes {
	padding:0;
}

.mobile-toolbar .rooms-menubar .rooms-checkboxes .p-checkbox{
	padding:0 !important;
	margin:0 0.2rem 1rem 1.2rem !important;
}

.mobile-toolbar .rooms-menubar .rooms-checkboxes .fa-fw {
	margin:0 !important;
	padding:0 !important;
}

.mobile-toolbar .rooms-menubar .rooms-checkboxes .pi-fw {
	margin:0 !important;
	padding:0 !important;
}

.mobile-toolbar .p-grid {
	margin: 0;
}

.mobile-toolbar a, .mobile-toolbar .disabled {
	margin-left:1.5em;
}

/* -- mobile lists -- */

.mlist {
	cursor: pointer;
}

.mitem {
	display: flex;
	flex-flow: row nowrap;
	justify-content:space-between;
	align-items:center;

	padding:0.75em 0.5em;
	border-bottom:1px solid #ddd;
	
}

.mileft {
	flex: 0 0 1.2em;
	align-self:flex-start;

	margin-right:0.5em;
}

.miright {
	flex: 0 0 0.2em;
	align-self:flex-start;

	color:#ccc;
}


.mibody {
	flex: 1;
}

.mibody.center {
	align-self:center;
}

.mitem:hover {
	background:#eef;
	transition: all 500ms;
}

.mitem:hover > .miright {
	font-weight:bold;
	color:black;
	transition:all 1s;
}

.mdetail {
	margin:0.5em 1em;
}


/* -- Mobile Helpers -- */

.mobile-frame .rooms-search {
	display:inline;
	padding:0 0 0 2em;
	background:none;
}


/* -- Kard -- */

.kard-stack {
	position:relative;
}

.kard {
	position:absolute;
	left:0;
	top:0;
	width:100vw;
	height:100vh;
	background:#eee;

	margin-left:0;

}

.knull, .khide {
	display:none;
}

.kshow {
	margin-left:0;
}

.kbelow {
	z-index:50;
}

.kslidein {
	animation-name: aslidein;
	animation-duration: 500ms;
}

.kslideout {
	animation-name: aslideout;
	animation-duration: 500ms;
}

.kslideup {
	animation-name: aslideup;
	animation-duration: 500ms;
}

.kslidedown {
	animation-name: aslidedown;
	animation-duration: 500ms;
}

@keyframes aslidein {
  from {
  	margin-left: 50vw;
	opacity:0.5;
	background:#999;
  }
  to {
	margin-left: 0;
	opacity:1;
	background:#eee;
  }
}

@keyframes aslideout {
  from {
  	margin-left: 0;
	opacity:1;
	background:#eee;
  }
  to {
	margin-left: 50vw;
	opacity:0;
	background:#999;
  }
}

@keyframes aslideup {
  from {
  	margin-top: 10vh;
	opacity:0.5;
	background:#999;
  }
  to {
	margin-top: 0;
	opacity:1;
	background:#eee;
  }
}

@keyframes aslidedown {
  from {
  	margin-top: -10vh;
	opacity:0.5;
	background:#999;
  }
  to {
	margin-top: 0;
	opacity:1;
	background:#eee;
  }
}

/* ---- Mobile Only above ---- */

